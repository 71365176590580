import { HeaderLogo } from './header-logo'
import { HeaderMenu } from './header-menu'
import styles from './index.module.css'

function Header() {
  return (
    <div className={styles.scoped}>
      <div className="header-wrap">
        <HeaderLogo />
        <HeaderMenu />
      </div>
    </div>
  )
}

export default Header
