import create from 'zustand'
import { createTrackedSelector } from 'react-tracked'
import produce from 'immer'
import { TlayoutProps } from '@/typings/api/layout'
import { extractFooterData } from '@/helper/layout/footer'
import { extractHeaderData } from '@/helper/layout/header'
import { devtools } from 'zustand/middleware'

type TLayoutStore = ReturnType<typeof getStore>

function getStore(set) {
  return {
    layoutProps: {} as any | TlayoutProps | undefined,
    setLayoutProps: (layoutProps?: any | TlayoutProps | undefined) =>
      set(() => {
        if (layoutProps) {
          return { layoutProps }
        }
        return {}
      }),
    footerData1: {} as any,
    homeGroupData: [] as any[],
    chainStarNewsStart: [] as any[],
    chainStarNewsEnd: [] as any[],
    footerData: undefined,
    setFooterData: data =>
      set(
        produce((draft: TLayoutStore) => {
          draft.footerData = data
        })
      ),
    headerData: { businessName: '' } as ReturnType<typeof extractHeaderData>,
    setHeaderData: data =>
      set(
        produce((draft: TLayoutStore) => {
          draft.headerData = data
        })
      ),
    fetchChainStarHomeGroupConfig: async params => {
      // const { isOk, data } = await fetchChainStarHomeGroupConfig(params)
      // if (isOk) {
      //   set(
      //     produce((draft: TLayoutStore) => {
      //       if (data) {
      //         draft.homeGroupData = data.configList || []
      //       }
      //     })
      //   )
      // }
    },
    columnsDataByCd: {} as Record<string, any>,
    setColumnsDataByCd: data =>
      set(
        produce((draft: TLayoutStore) => {
          draft.columnsDataByCd = data
        })
      ),
  }
}
const baseLayoutStore = create(devtools(getStore, { name: 'layout-store' }))

const useLayoutStore = createTrackedSelector(baseLayoutStore)

export { useLayoutStore, baseLayoutStore }
