import { addGlobalLibrary } from '@/helper/externals'
import { I18nsEnum } from '@/constants/i18n'
import { logGitCommitId } from '../common'
import { initSentry } from './utils/sentry'
import { getDeviceId } from './utils/client-device-id'
import { initCache } from '../cache/common'
import { initWS } from './utils/init-ws'
import { initClientApi, initServerApi } from './utils/init-api'
import { initVConsoleOnDev } from '../log'
import { clientInjectFirst } from './utils/inject'
import { handleRouterLocale } from './utils/init-locale'

/**
 * 初始化 客户端能力，例如注册 ws
 */
export const onInstallForClient = async (pageContext: PageContext) => {
  // initSentry()
  getDeviceId()
  /** 探测持久化储存 */
  initCache()
  /** 添加全局库 */
  addGlobalLibrary()
  /** 高优先级任务，例如打通 webview token */
  const isBlock = await clientInjectFirst(pageContext)
  // dev vConsole
  initVConsoleOnDev()
  /** 注册 WS */
  // initWS()
  /** 注册 api */
  initClientApi()
  /** 额外功能 */
  logGitCommitId()
  // 调试 layoutstore 数据源
  /** 处理路由传入的多语言 */
  handleRouterLocale(pageContext)
  // const res = await initServerApi({ locale: I18nsEnum['zh-CN'] } as any)
  // console.log(res, '---')
  if (isBlock === true) {
    return true
  }
}
