import Icon from '@/components/icon'
import { Trigger } from '@nbit/arco'
import { useState } from 'react'
import { link } from '@/helper/link'
import styles from './index.module.css'
import { MenuCellListType } from '..'
import { I18nSelect } from '../../language-select'

type IMenuCellsProps = {
  menuList: MenuCellListType[]
  onClickMenu?: (v: MenuCellListType) => void
}

function MenuCells({ menuList, onClickMenu }: IMenuCellsProps) {
  const onClick = (v: MenuCellListType) => {
    link(v.href)
    onClickMenu && onClickMenu(v)
  }

  return (
    <div className={styles.scoped}>
      {menuList.map(v => {
        if (!v?.h5) return null
        return (
          <div className="cell-wrap" key={v.href} onClick={() => onClick(v)}>
            <div className="text">
              <span>{v.text}</span>
            </div>
          </div>
        )
      })}
    </div>
  )
}

function H5HeaderMenu({ menuList }: { menuList: MenuCellListType[] }) {
  const [popupVisible, setPopupVisible] = useState(false)
  return (
    <div className={styles['h5-menu-wrap']}>
      <Icon name="download_icon" hasTheme className="mr-4 mt-px" onClick={() => link('/wallet')} />
      <I18nSelect popupAlign={{ bottom: 10 }} />
      <Trigger
        popupAlign={{
          bottom: 10,
        }}
        popup={() => <MenuCells menuList={menuList} onClickMenu={() => setPopupVisible(false)} />}
        onVisibleChange={setPopupVisible}
        popupVisible={popupVisible}
      >
        <div className="ml-4" onClick={() => setPopupVisible(!popupVisible)}>
          <Icon name="icon_more" />
        </div>
      </Trigger>
    </div>
  )
}

export default H5HeaderMenu
