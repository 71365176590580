import { I18nsEnum, I18nsMap, areaCodeImageMap } from '@/constants/i18n'
import { useCommonStore } from '@/store/common'
import { setLocale } from '@/helper/i18n'
import { Trigger } from '@nbit/arco'
import LazyImage from '@/components/lazy-image'
import Icon from '@/components/icon'
import { oss_area_code_image_domain_address } from '@/constants/oss'
import { useState } from 'react'
import classNames from 'classnames'
import { envIsCommon } from '@/helper/env'
import styles from './index.module.css'

type I18nSelectProps = {
  popupAlign?: {
    left?: number | [number, number]
    right?: number | [number, number]
    top?: number | [number, number]
    bottom?: number | [number, number]
  }
}

export function I18nSelect({ popupAlign }: I18nSelectProps) {
  const { locale } = useCommonStore()
  const [opened, setOpened] = useState(false)

  const changeI18n = (lang: I18nsEnum) => {
    if (lang === locale) {
      return
    }
    setLocale(lang)
  }

  return (
    <Trigger
      trigger={['click', 'hover']}
      position="bottom"
      popupAlign={popupAlign}
      onVisibleChange={setOpened}
      className={styles.scoped}
      popup={() => {
        return (
          <div className={styles['trigger-popup']}>
            <div className="container">
              {Object.keys(I18nsMap).map(lang => (
                <div
                  key={lang}
                  className={classNames('i18n-item', !envIsCommon && 'i18n-item-pu')}
                  onClick={() => changeI18n(lang as I18nsEnum)}
                >
                  <LazyImage
                    className="area-icon"
                    src={`${oss_area_code_image_domain_address}${areaCodeImageMap(lang)[1]}`}
                  />
                  <div>{I18nsMap[lang]}</div>
                </div>
              ))}
            </div>
          </div>
        )
      }}
    >
      <div className={styles['web-i18n-select-box']}>
        <LazyImage className="area-icon" src={`${oss_area_code_image_domain_address}${areaCodeImageMap(locale)[1]}`} />
        <span className="text">{areaCodeImageMap(locale)[0]}</span>
        <Icon className="icon" name={opened ? 'icon_away' : 'icon_drop'} />
      </div>
      <div className={styles['h5-i18n-select-box']}>
        <Icon className="icon" name={'icon_language'} />
      </div>
    </Trigger>
  )
}
