import Link from '@/components/link'
import Icon from '@/components/icon'
import { ReactNode } from 'react'
import { oss_svg_image_domain_address } from '@/constants/oss'
import { t } from '@lingui/macro'
import { useCommonStore } from '@/store/common'
import classNames from 'classnames'
import { envIsCommon } from '@/helper/env'
import { getHeaderRoutes } from './header-router'
import styles from './index.module.css'
import H5HeaderMenu from './h5-menu'
import { I18nSelect } from '../language-select'

export interface MenuCellListType {
  /** 图标 */
  icon: ReactNode
  /** 文字 */
  text: string
  id?: string
  /** 路由地址 */
  href?: string
  h5?: boolean
  onClick?: () => void
}

export function HeaderMenu() {
  const menuList = getHeaderRoutes()
  const h5MenuList = [...menuList]
  const { locale } = useCommonStore()
  //  {
  //     text: `下载 app`,
  //     icon: 'icon_top_wallet_app',
  //     href: '/wallet',
  //     h5: false,
  //   },
  return (
    <div className={styles.scoped}>
      <H5HeaderMenu menuList={h5MenuList} />
      <div className="web-menu">
        <div className="home-about">
          {menuList.map(({ text, href, icon }) => {
            return (
              <Link
                className={classNames('menu-item', envIsCommon ? 'hover-item' : 'hover-item-pu')}
                key={icon}
                href={href}
              >
                <div>
                  {icon && <Icon name={icon} className="menu-icon" />}
                  <span>{text}</span>
                </div>
              </Link>
            )
          })}
        </div>
        <div className="menu-item">
          <Link href={'/wallet'}>
            <div
              className={classNames(
                'app-dow',
                locale === 'en-US' && 'app-dow-us',
                envIsCommon ? 'app-dow-new' : 'app-dow-pu'
              )}
            >
              <img src={`${oss_svg_image_domain_address}IM-chat-cn/home/icon_index_Download_new.svg`} alt="" />
              <span className="down-app">{t`features_layout_header_header_menu_index_rdclqad38l`}</span>
            </div>
          </Link>
          <div className={classNames('language-select', envIsCommon ? 'hover-item' : 'hover-item-pu')}>
            <I18nSelect popupAlign={{ bottom: 12 }} />
          </div>
        </div>
      </div>
    </div>
  )
}
