import { baseLayoutStore } from '@/store/layout'
import { TlayoutProps } from '@/typings/api/layout'
import { YapiGetV1HomeColumnGetListColumnsDatasData } from '@/typings/yapi/HomeColumnGetListV1GetApi'
import { cloneDeep, pick } from 'lodash'

const settings = 'common_settings'

function extractFooterData(layoutProps?: TlayoutProps) {
  return {}
  // if (layoutProps) {
  //   // prevent in-place manipulation
  //   const footer = cloneDeep(pick(layoutProps, ['groupConfigDatas', 'columnsDatas', 'webCopyright', 'businessName']))
  //   footer.columnsDatas = recursiveFilter([...footer.columnsDatas!], col => col.isWeb === 1).filter(
  //     col => col.homeColumnCd !== settings
  //   )
  //   return footer
  // }
}

function recursiveColumnMap(columnsDatas: YapiGetV1HomeColumnGetListColumnsDatasData[]) {
  let mapped = {}

  function recursiveSearch(columns: YapiGetV1HomeColumnGetListColumnsDatasData[]) {
    if (!columns || columns.length === 0) return
    columns.forEach(col => {
      recursiveSearch(col.childColumns as unknown as YapiGetV1HomeColumnGetListColumnsDatasData[])
      if (col.homeColumnCd) mapped[col.homeColumnCd] = col
    })
  }

  recursiveSearch(columnsDatas)

  return mapped
}

export { extractFooterData, recursiveColumnMap }
