import { envIsCommon } from '@/helper/env'
import { t } from '@lingui/macro'

export const getHeaderRoutes = () => {
  return [
    {
      text: t`features_layout_header_header_menu_header_router_znrdghrxw5`,
      icon: '',
      href: '/',
      h5: true,
    },
    {
      text: t`features_about_us_h5_index_tal2hsmujj`,
      icon: '',
      href: '/about',
      h5: true,
    },
  ]
}
