import Link from '@/components/link'

import { oss_svg_image_domain_address } from '@/constants/oss'
import { envIsCommon } from '@/helper/env'
import styles from './index.module.css'

export function HeaderLogo() {
  return (
    <Link href="/" className={styles.scoped}>
      <img
        src={
          envIsCommon
            ? `${oss_svg_image_domain_address}best-chat/logo.png`
            : `${oss_svg_image_domain_address}im-plus/logo_pu.svg`
        }
        alt="Best Chat Logo"
      />
    </Link>
  )
}
