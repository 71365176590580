/**
 * footer
 */
import { oss_svg_image_domain_address } from '@/constants/oss'
import Link from '@/components/link'
import { t } from '@lingui/macro'
import { H5Url, envIsCommon } from '@/helper/env'
import { useCommonStore } from '@/store/common'
import { useLayoutStore } from '@/store/layout'
import styles from './index.module.css'
import FooterMenu from './footer-menu'

function Footer() {
  const { columnsDataByCd } = useLayoutStore()
  let linkPrivacy = '/private'
  let link = columnsDataByCd?.terms_service?.h5Url

  return (
    <div className={styles['footer-root']}>
      <div className="footer-head">
        <img
          src={
            envIsCommon
              ? `${oss_svg_image_domain_address}best-chat/logo.png`
              : `${oss_svg_image_domain_address}im-plus/logo_pu.svg`
          }
          className="footer-logo"
          alt=""
        />
      </div>
      {/* <div className="footer-wrap">
        <div className="footer-content  ">
          <FooterMenu />
        </div>
      </div> */}
      <div className="footer-center">© 2021-2024 Best Chat</div>
      <div className="footer-bottom">
        <Link href="/about">
          <div>{t`features_layout_footer_index_qthixtf1pr`}</div>
        </Link>
        <div className="mx-10">|</div>
        <Link href="/account-logout">
          <div>{t`features_layout_footer_index_duswrsrry3`}</div>
        </Link>
        {linkPrivacy && (
          <>
            <div className="mx-10">|</div>
            <Link href={linkPrivacy}>
              <div>{t`features_layout_footer_index_duswrsrry2`}</div>
            </Link>
          </>
        )}

        {/* <div>{t`features_account_logout_index_dqdptu6ykc`}</div> */}
        {/* <div className="mx-10">|</div> */}
        {/* <Link href="https://im-h5.talkblink.com/zh-CN/support/article/810301">
          <div>{t`features_layout_footer_index_duswrsrry2`}</div>
        </Link> */}
      </div>
      <div className="h5-footer-bottom">
        <div className="h5-box">
          <Link href="/about">
            <div>{t`features_layout_footer_index_qthixtf1pr`}</div>
          </Link>
          <div className="footer-line my-4"></div>
          <Link href="/account-logout">
            <div>{t`features_layout_footer_index_duswrsrry3`}</div>
          </Link>
          {linkPrivacy && (
            <>
              <div className="footer-line my-4"></div>
              <Link href={linkPrivacy}>
                <div>{t`features_layout_footer_index_duswrsrry2`}</div>
              </Link>
            </>
          )}

          {/* <div>{t`features_account_logout_index_dqdptu6ykc`}</div> */}
          {/* <div className="footer-line my-4"></div> */}
          {/* <Link href="https://im-h5.talkblink.com/zh-CN/support/article/810301">
            <div>{t`features_layout_footer_index_duswrsrry2`}</div>
          </Link> */}
        </div>
      </div>
    </div>
  )
}

export default Footer
